import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { useReduxDispatch } from '../redux/store';
import queryString from 'query-string';

const QuestionNavigator = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useReduxDispatch();

    const contestState = useSelector((state: any) => state.contest);

    const {
        codingQuestions,
        contestStarted,
        contestEnded,
        contestId,
        totalAttempts,
        contestHash,
        webDevQuestions,
        showModal,
        submitCallMade,
        isAttempted,
        forcefullyStart,
        conceptualQuestions,
    } = contestState;

    const [active, setActive] = useState<string>('');

    useEffect(() => {
        const questionId: Array<string | null> | null | string =
            queryString.parse(location.search).question;
        console.log('Active Question', questionId);
        if (typeof questionId === 'string') {
            setActive(questionId);
        }
    }, [location]);

    return (
        <div className={'question-navigator'}>
            {/*<div*/}
            {/*    className={'all-btn'}*/}
            {/*    onClick={() => {*/}
            {/*        navigate(`/contest/all-questions?contestID=${contestId}`);*/}
            {/*    }}*/}
            {/*>*/}
            {/*    All*/}
            {/*</div>*/}

            <ul>
                <li
                    className={'all-btn'}
                    onClick={() => {
                        navigate(
                            `/contest/all-questions?contestID=${contestId}`
                        );
                    }}
                >
                    All
                </li>
                {conceptualQuestions.map((el: any, index: number) => (
                    <li
                        className={`${
                            active === el.questionId ? 'active-question' : ''
                        }`}
                        key={el.questionId}
                        onClick={() => {
                            navigate(
                                `/contest/solve/conceptual-question?contestID=${contestId}&question=${el.questionId}&pool=${el.poolId}`
                            );
                        }}
                    >
                        {index + 1}
                    </li>
                ))}
                {codingQuestions.map((el: any, index: number) => (
                    <li
                        className={`${
                            active === el.questionId ? 'active-question' : ''
                        }`}
                        key={el.questionId}
                        onClick={() => {
                            navigate(
                                `/contest/solve/coding-question?contestID=${contestId}&question=${el.questionId}&pool=${el.poolId}`
                            );
                        }}
                    >
                        {conceptualQuestions.length + index + 1}
                    </li>
                ))}
                {webDevQuestions.map((el: any, index: number) => (
                    <li
                        className={`${
                            active === el.questionId ? 'active-question' : ''
                        }`}
                        key={el.questionId}
                        onClick={() => {
                            navigate(
                                `/contest/solve/web-dev-question?contestID=${contestId}&question=${el.questionId}&pool=${el.poolId}`
                            );
                        }}
                    >
                        {conceptualQuestions.length +
                            codingQuestions.length +
                            index +
                            1}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default QuestionNavigator;