import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import authReducer from './auth/authSlice';
import editorReducer from './editor/editorSlice';
import contestReducer from './contest/contestSlice';
import userReducer from './user/userSlice';
import moduleReducer from './modules/moduleSlice';
import courseReducer from './course/courseSlice';
// @ts-ignore
import {
    createStateSyncMiddleware,
    initStateWithPrevTab,
} from 'redux-state-sync';
import webDevQuestionReducer from './webDevQuestion/webDevQuestionSlice';

const storeSync = configureStore({
    reducer: {
        auth: authReducer,
        user: userReducer,
        contest: contestReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(createStateSyncMiddleware()),
});

const store = configureStore({
    reducer: {
        auth: authReducer,
        user: userReducer,
        editor: editorReducer,
        contest: contestReducer,
        webDevQuestion: webDevQuestionReducer,
        module: moduleReducer,
        course: courseReducer,
    },
});

initStateWithPrevTab(storeSync);
export default store;

export type ReduxState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useReduxDispatch: () => AppDispatch = useDispatch;
