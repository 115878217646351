import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Questions from '../questions/questions';
import ContestNavigation from '../../components/contestNavigation/contestNavigation';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import Timer from '../../components/timer';

import {
    fetchProctorInfo,
    forceStart,
    showEndModal,
    startContest,
    submitContest,
} from '../../redux/contest/contestSlice';
// @ts-ignore
import $ from 'jquery';

// @ts-ignore
import CryptoJS from 'crypto-js';
import success from '../../assets/lottie/success.json';
import Lottie from 'react-lottie-player';
import waitingLottie from '../../assets/lottie/waiting.json';
import QuestionScreen from './questionScreen';
import { useReduxDispatch } from '../../redux/store';
import queryString from 'query-string';
import { engagements } from '../../engagements';

const Contest = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useReduxDispatch();
    const contestID: Array<string | null> | null | string = queryString.parse(
        location.search
    ).contestID;
    console.log('CONTEST ID', contestID);

    useEffect(() => {
        if (
            contestID === null ||
            contestID === undefined ||
            contestID.length < 36
        ) {
            navigate('/');
        }
    }, []);

    const [autoProctoring, setAutoProctoring] = useState(true);

    useEffect(() => {
        const status = localStorage.getItem('auto-proctoring');
        console.log('auto-proctoring status', status);
        if (status === null) {
            localStorage.setItem('auto-proctoring', 'enable');
        } else {
            if (status === 'enable') {
                setAutoProctoring(true);
            } else {
                setAutoProctoring(false);
            }
        }
    }, []);

    const contestState = useSelector((state: any) => state.contest);

    const {
        codingQuestions,
        contestData,
        contestStarted,
        contestEnded,
        contestId,
        totalAttempts,
        contestHash,
        webDevQuestions,
        showModal,
        submitCallMade,
        isAttempted,
        forcefullyStart,
        afterSubmissionScore,
    } = contestState;

    const [active, setActive] = useState('');
    const [showQuestionNav, setShowQuestionNav] = useState(false);

    const [contestSubmitted, setContestSubmitted] = useState(false);

    const [showForceModel, setShowForceModel] = useState(false);
    const [normalProctoringStarted, setNormalProctoringStarted] =
        useState(false);

    const [waiting, setWaiting] = useState(false);

    window.addEventListener('apStartTest', () => {
        dispatch(startContest());
        engagements.contestEngagement.contestStarted(
            contestData.name,
            contestId,
            true
        );
        navigate(`/contest/all-questions?contestID=${contestId}`);
        setWaiting(false);
        if (!forcefullyStart) {
            localStorage.setItem('auto-proctoring', 'enable');
        }
    });

    window.addEventListener('startProctoring', () => {
        console.log('start Proctoring triggered');
        console.log('setShowForceModel', showForceModel);
        setShowForceModel(false);
        setNormalProctoringStarted(true);
        dispatch(forceStart(false));
    });

    useEffect(() => {
        if (contestId !== null && contestId.length === 36) {
            dispatch(fetchProctorInfo(contestId));
        }
    }, [contestId]);

    useEffect(() => {
        if (totalAttempts > 10) {
            //@ts-ignore
            dispatch(submitContest(contestId));
            // let event = new Event('endContestEvent');
            // window.dispatchEvent(event);
            console.log('ENDING CONTEST DUE EXCESS ATTEMPTS');
        }
    }, [totalAttempts]);

    const [disableRefresh, setDisableRefresh] = useState(true);

    const [fullScreen, setfullScreen] = useState(false);

    const forceStartContest = () => {
        dispatch(forceStart(true));
        setShowForceModel(true);
    };

    document.addEventListener('fullscreenchange', (event) => {
        if (forcefullyStart && !IsFullScreenCurrently()) {
            setfullScreen(false);
        }
    });

    useEffect(() => {
        if (fullScreen && forcefullyStart) {
            let evn = new Event('apStartTest');
            window.dispatchEvent(evn);
        }
    }, [forcefullyStart, fullScreen]);

    const timeleft = new Date();
    timeleft.setSeconds(timeleft.getSeconds() + 180);
    const obj = { expiryTimestamp: timeleft };

    const expiryHandler = () => {
        setDisableRefresh(false);
        if (!contestStarted && !normalProctoringStarted) {
            forceStartContest();
        }
    };

    useEffect(() => {
        if (!autoProctoring && waiting) {
            const timer = setTimeout(() => {
                if (!contestStarted && !normalProctoringStarted) {
                    forceStartContest();
                }
            }, 10000);
            return () => clearTimeout(timer);
        }
    });

    const endContestHandler = () => {
        console.log('FROM END CONTEST HANDLER');
        if (forcefullyStart) {
            let event = new Event('apStopMonitoring');
            window.dispatchEvent(event);
            if (document.exitFullscreen) document.exitFullscreen();
        } else {
            let evn = new Event('endContestEvent');
            window.dispatchEvent(evn);
        }
        dispatch(showEndModal());
    };

    const [proctoringEnded, setProctoringEnded] = useState(false);

    useEffect(() => {
        if (proctoringEnded && !contestSubmitted) {
            //@ts-ignore
            dispatch(submitContest(contestId));
            setContestSubmitted(true);
        }
    }, [contestSubmitted, proctoringEnded]);

    window.addEventListener('apStopMonitoring', () => {
        console.log('STOP MONITORING TRIGGERED');
        setProctoringEnded(true);
    });

    /* Get into full screen */
    function GoInFullscreen(element: any) {
        if (element.requestFullscreen) element.requestFullscreen();
        else if (element.mozRequestFullScreen) element.mozRequestFullScreen();
        else if (element.webkitRequestFullscreen)
            element.webkitRequestFullscreen();
        else if (element.msRequestFullscreen) element.msRequestFullscreen();
        setfullScreen(true);
    }

    /* Is currently in full screen or not */
    function IsFullScreenCurrently() {
        const full_screen_element = document.fullscreenElement || null;
        return full_screen_element !== null;
    }

    return (
        <div
            className={'contest'}
            id={'contest-root'}
        >
            {showModal ? (
                <div className={'modal overlay'}>
                    <div className={'modal-container'}>
                        {contestEnded ? (
                            <>
                                <h3>
                                    Your contest has been submitted successfully{' '}
                                </h3>
                                <Lottie
                                    loop
                                    animationData={success}
                                    play
                                    className={'animation'}
                                />

                                <h2 style={{ margin: '10px' }}>
                                    You have scored : {afterSubmissionScore}
                                </h2>
                                <p>
                                    You can close the contest window by clicking
                                    below; if it doesn't close, you can close
                                    manually
                                </p>
                                <button
                                    onClick={() => {
                                        window.close();
                                    }}
                                >
                                    Close Window
                                </button>
                            </>
                        ) : (
                            <>
                                <Lottie
                                    loop
                                    animationData={waitingLottie}
                                    play
                                    style={{ width: 250, height: 250 }}
                                    // className={'animation'}
                                />
                                <h3 style={{ textAlign: 'center' }}>
                                    Do not close the window, we are submitting
                                    your contest.
                                </h3>
                            </>
                        )}
                    </div>
                </div>
            ) : null}

            {!fullScreen &&
            forcefullyStart &&
            !contestEnded &&
            showForceModel ? (
                <div className={'modal overlay'}>
                    <div className={'modal-container'}>
                        <>
                            <h3 style={{ textAlign: 'center' }}>
                                Contest can only be attempted in full screen
                                mode.
                            </h3>
                            <button
                                onClick={() => {
                                    setfullScreen(true);
                                    GoInFullscreen($('#root').get(0));
                                }}
                            >
                                Go to Full Screen
                            </button>
                        </>
                    </div>
                </div>
            ) : null}

            <ContestNavigation endContestHandler={endContestHandler} />
            <div
                className={'proctoring-options'}
                id={'ap-section'}
            >
                <div
                    className={'notification'}
                    id="proctor-feedback"
                ></div>
            </div>
            {!showModal ? (
                contestStarted && !contestEnded ? (
                    <>
                        <div className={'main-screen'}>
                            <div className={'contest-routes'}>
                                <Routes>
                                    <Route
                                        path={'all-questions'}
                                        element={<Questions />}
                                    />
                                    <Route
                                        path={'solve/*'}
                                        element={<QuestionScreen />}
                                    />
                                </Routes>
                            </div>
                        </div>
                    </>
                ) : !isAttempted ? (
                    <div className="contest-start-popup">
                        {!waiting ? (
                            <>
                                <div className={'before-start-info'}>
                                    <h2>Proctoring Instructions</h2>
                                    <ul className={'instructions-list'}>
                                        <li>
                                            The test is PROCTORED, make sure you
                                            don't cheat or else your submission
                                            will be disqualified.
                                        </li>
                                        <li>
                                            Allow access to webcam, microphone
                                            and share to your screen for
                                            proctoring, otherwise the test will
                                            not start.
                                        </li>
                                        <li>
                                            If your face is not detected from
                                            the webcam, the test will not load.
                                            There must be enough light in the
                                            room you are taking the test, and no
                                            one else should be present in the
                                            room.
                                        </li>
                                        <li>
                                            You cannot leave the Full Screen
                                            Mode once the test starts. Do not
                                            shift to a different tab during the
                                            test.
                                        </li>
                                    </ul>
                                </div>
                                <button
                                    id={'testStarttt'}
                                    onClick={() => {
                                        setWaiting(true);
                                        if (autoProctoring) {
                                            let event = new Event('initialize');
                                            window.dispatchEvent(event);
                                        }
                                    }}
                                >
                                    Start Test
                                </button>
                            </>
                        ) : (
                            <div className={'waiting-prompt'}>
                                <Lottie
                                    loop
                                    animationData={waitingLottie}
                                    play
                                    style={{ width: 250, height: 250 }}
                                />
                                <p>
                                    Please accept all prompts coming to you,
                                    this might take few minutes. Please try
                                    refreshing if it take more than 3 min or
                                    some error occurred.
                                </p>
                                <div className={'wait-timer'}>
                                    <Timer
                                        expiryTimestamp={timeleft}
                                        show={[0, 0, 1, 1]}
                                        expiryHandler={expiryHandler}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                ) : (
                    <div className="contest-start-popup">
                        <div className={'before-start-info'}>
                            <h2>You have already submitted this contest</h2>
                            <button
                                onClick={() => {
                                    navigate('/');
                                }}
                            >
                                {' '}
                                Go Home
                            </button>
                        </div>
                    </div>
                )
            ) : null}
        </div>
    );
};

export default Contest;
