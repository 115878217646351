import axios, { AxiosRequestConfig, AxiosError, AxiosResponse } from 'axios';
import { TokenService, CourseTokenService } from '../token';

export interface HandleStatusCode {
    [key: number]: (error: AxiosError) => void;
}

export const apiFailureErrorMessage = 'Failure in API call';

export class HttpService {
    constructor(
        protected tokenService: TokenService,
        protected courseTokenService: CourseTokenService,
    ) {
    }

    async get<T>(
        url: string,
        config?: AxiosRequestConfig,
    ): Promise<AxiosResponse<T>> {
        console.log('GET', url, config);
        return this.request<T>({ method: 'GET', url, ...config });
    }

    async post<T>(
        url: string,
        config?: AxiosRequestConfig,
    ): Promise<AxiosResponse<T>> {
        return this.request<T>({ method: 'POST', url, ...config });
    }

    async put<T>(
        url: string,
        config?: AxiosRequestConfig,
    ): Promise<AxiosResponse<T>> {
        return this.request<T>({ method: 'PUT', url, ...config });
    }

    async patch<T>(
        url: string,
        config?: AxiosRequestConfig,
    ): Promise<AxiosResponse<T>> {
        return this.request<T>({ method: 'PATCH', url, ...config });
    }

    async delete<T>(
        url: string,
        config?: AxiosRequestConfig,
    ): Promise<AxiosResponse<T>> {
        return this.request<T>({ method: 'DELETE', url, ...config });
    }

    async request<T>(config: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const token: string = await this.tokenService.getToken();
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const courseID: string = await this.courseTokenService.getToken();
        console.log('COURSE ID FROM LOCAL STORAGE', courseID);
        const course = '196204d3-b5b7-4f93-bf37-1830080a5732';
        const defaultRequestInfo: AxiosRequestConfig = {
            headers: {
                // 'Content-Type': 'application/json;charset=UTF-8',
                maxContentLength: Infinity,
                maxBodyLength: Infinity,
                courseid: courseID,
                'acciojobs-token': token,
                // 'acciojobs-token': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6Imluc3RydWN0b3JzYmF0Y2g4QG1haWxpbmF0b3IuY29tIiwiaWQiOiI2NmEzODU4Ni1lNjYyLTRiOTMtOWYxOC1iYmUyMTc2ZDM4YjQiLCJyb2xlcyI6WyJwcmVjb3Vyc2UtdXNlciJdLCJpYXQiOjE2NTg0ODkwODJ9.GD-z4sknIN3wCvs8nzMomNNRL90i-sNrNkTrRPbD-ec',
            },
        };
        return axios.request<T>({
            ...defaultRequestInfo,
            ...config,
            headers: {
                ...defaultRequestInfo.headers,
                ...config.headers,
            },
        });
    }

    onHttpErrorStatus(
        error: AxiosError,
        handleStatusCode: HandleStatusCode,
    ): void {
        if (error.response && error.response.status) {
            const statusCode = error.response.status;
            const handleStatusCodeFn = handleStatusCode[statusCode];

            if (typeof handleStatusCodeFn === 'function') {
                handleStatusCodeFn(error);
            }
        }
    }
}
