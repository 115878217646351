import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

// Only External Exports Above
import './App.scss';
import './styles/style.scss';
import { useReduxDispatch } from './redux/store';
import AppRouters from './AppRouters';
import {
    fetchAllContests,
    fetchContests,
    setContestId,
    setContestSelected,
} from './redux/contest/contestSlice';

import useLoginHook from './custom_hooks/useLoginHook';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallBack from './screens/ErrorFallBack';

function App() {
    const isAuthenticated = useLoginHook();
    const dispatch = useReduxDispatch();
    const location = useLocation();
    const contestID: Array<string | null> | null | string = queryString.parse(
        location.search
    ).contestID;
    console.log('CONTEST ID', contestID);


    useEffect(() => {
        if (
            contestID !== undefined &&
            contestID !== null &&
            contestID.length === 36
        ) {
            dispatch(setContestId(contestID));
            if (typeof contestID === 'string') {
                dispatch(fetchContests(contestID));
            }
            dispatch(setContestSelected(true));
        } else {
            dispatch(fetchAllContests());
        }
    }, []);

    return (
        <ErrorBoundary
            FallbackComponent={ErrorFallBack}
            onReset={() => {}}
        >
            {isAuthenticated ? <AppRouters /> : null}
        </ErrorBoundary>
    );
}

export default App;
