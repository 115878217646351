import React, { useEffect, useState } from 'react';
import './../../styles/aptitude.css';
import Mcq from './components/Mcq';
import IntegerType from './components/Integer';
import Subjective from './components/Subjective';
import MultiSelect from './components/MultiSelect';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
// import {
//     fetchAptitudeQuestions,
//     submitAptitudeQuestions,
// } from '../../redux/modules/moduleSlice';
import { services } from '../../api';
import Loader from '../../components/Loader';

const Aptitude = () => {
    const location = useLocation();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const question: string = queryString.parse(location.search).question;
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const contestState = useSelector((state: any) => state.contest);

    const { conceptualQuestions, alreadySubmitted, contestId } = contestState;
    const [questionStatement, setQuestionStatement] = useState('');
    const [options, setOptions] = useState([]);
    const [questionNumber, setQuestionNumber] = useState(0);
    const [questionType, setQuestionType] = useState('');
    const [answerId, setAnswerId] = useState(undefined);
    const [answer, setAnswer] = useState<any>([]);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [showSubmitModal, setShowSubmitModal] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [submitWarning, setSubmitWarning] = useState(true);
    const [poolId, setPoolId] = useState(null);

    // const [correctAnswer, setCorrectAnswer] = useState([]);

    const [loadAptitudeQuestion, setLoadAptitudeQuestion] = useState(false);
    const [score, setScore] = useState(0);

    const getQuestionById = async (question: string) => {
        setLoadAptitudeQuestion(true);
        try {
            const body = {
                questionId: question,
                contestId: contestId,
            };
            const res =
                await services.codingQuestionService.getAptitudeQuestionById(
                    body
                );
            setAnswer([]);
            console.log('Aptitude Question temp', res, res.answer);
            if (res.answer !== null) {
                setAnswerId(res.answer.id);
                setAnswer(res.answer);
            } else {
                setAnswerId(undefined);
                setAnswer([]);
            }
            // setCorrectAnswer(res.correctAnswer);
            return res;
            setLoadAptitudeQuestion(false);
        } catch (e) {
            console.log(e);
        }
    };

    const saveQuestion = async (answerArray: string[]) => {
        const data = {
            questionId: question,
            userAnswer: answerArray,
            contestId: contestId,
            poolId: poolId,
        };

        setAnswer(answerArray);

        try {
            const res =
                await services.codingQuestionService.saveAptitudeQuestions(
                    data
                );
            console.log('Save Aptitude Question temp', res, res.answer);
            return res.question;
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        (async () => {
            if (conceptualQuestions.length > 0) {
                let id = question;
                const temp = conceptualQuestions.findIndex(
                    (el: any) => el.questionId === id
                );
                setPoolId(conceptualQuestions[temp].poolId);
                console.log(temp);
                setQuestionNumber(temp + 1);
                const questionData = await getQuestionById(id);
                console.log('temp', questionData);
                setQuestionStatement(questionData.statement);
                setQuestionType(questionData.questionType);
                setOptions(questionData.answerOptions);
                setScore(questionData.score);
                setIsSubmitted(alreadySubmitted);
            }
        })();
    }, [conceptualQuestions, question]);

    const submit = () => {
        setShowSubmitModal(true);
        setIsSubmitted(true);
    };

    const questionTypeMap = {
        SINGLEMCQ: 'Single Select',
        MULTIMCQ: 'Multi Select',
        TEXT: 'Subjective Type',
        INT: 'Integer Type',
    };

    return (
        <div className={'aptitude'}>
            <div className={'column-1'}>
                <div className={'heading'}>
                    <h1>Conceptual Questions</h1>
                </div>
                <p className={'meta-info'}>
                    Individual objective & multiple select questions answers
                    will be saved automatically. Press save to save answers for
                    subjective and integer type questions.
                </p>
                {loadAptitudeQuestion ? (
                    <div className={'questionContainer'}>
                        <div className={'question-bar'}>
                            <h1>Question : {questionNumber}</h1>
                            <h4>Marks : {score}</h4>
                        </div>
                        <p className={'question-type'}>
                            {/*@ts-ignore*/}
                            {questionTypeMap[questionType]}
                        </p>
                        <div className={'question-panel'}>
                            {questionType === 'SINGLEMCQ' &&
                            loadAptitudeQuestion ? (
                                <Mcq
                                    statement={questionStatement}
                                    options={options}
                                    save={saveQuestion}
                                    response={answer}
                                    // correct={correctAnswer}
                                />
                            ) : null}
                            {questionType === 'INT' && loadAptitudeQuestion ? (
                                <IntegerType
                                    statement={questionStatement}
                                    options={options}
                                    save={saveQuestion}
                                    response={answer}
                                    // correct={correctAnswer}
                                />
                            ) : null}
                            {questionType === 'TEXT' && loadAptitudeQuestion ? (
                                <Subjective
                                    statement={questionStatement}
                                    options={options}
                                    save={saveQuestion}
                                    response={answer}
                                    // correct={correctAnswer}
                                />
                            ) : null}
                            {questionType === 'MULTIMCQ' &&
                            loadAptitudeQuestion ? (
                                <MultiSelect
                                    statement={questionStatement}
                                    options={options}
                                    save={saveQuestion}
                                    response={answer}
                                    // correct={correctAnswer}
                                />
                            ) : null}
                        </div>
                    </div>
                ) : (
                    <Loader />
                )}
            </div>
        </div>
    );
};

export default Aptitude;
