import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { services } from '../../api';
// @ts-ignore
import CryptoJS from 'crypto-js';

import { engagements } from '../../engagements';

export const fetchAllContests = createAsyncThunk(
    'contest/fetchAllContests',
    async () => {
        try {
            const res = await services.contestService.fetchAllContest();
            console.log('ALL CONTESTS', res);
            return res;
        } catch (error) {
            console.log(error);
        }
    }
);

export const fetchContests = createAsyncThunk(
    'contest/fetchContests',
    async (contestId: string) => {
        try {
            const res = await services.contestService.fetchContest({
                contestId: contestId,
            });
            console.log('CONTEST SLICE', res);
            return res;
        } catch (error) {
            console.log(error);
        }
    }
);

export const submitContest = createAsyncThunk(
    'contest/submitContest',
    async (contestId: string, { getState }) => {
        try {
            const state = getState();
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            const { contestData } = state.contest;
            const res = await services.contestService.submitContest({
                contestId: contestId,
            });
            console.log('CONTEST SUBMITTED HUMARA', res);
            engagements.contestEngagement.contestEnded(
                contestData.name,
                contestId,
                true,
                '0',
                true
            );
            return res;
        } catch (error) {
            console.log(error);
        }
    }
);

export const fetchQuestions = createAsyncThunk(
    'contest/fetchQuestions',
    async (body: any) => {
        const testAttemptId = await localStorage.getItem('testAttemptId');
        const hashedTestAttemptId = await localStorage.getItem(
            'hashedTestAttemptId'
        );
        console.log('Question Body', body);
        const data = await services.contestService.fetchQuestions({
            contestId: body.contestId,
            contestHash: body.contestHash,
            testAttemptId: testAttemptId,
            hashedTestAttemptId: hashedTestAttemptId,
        });
        console.log('CONTEST QUESTION', data);
        return data.data;
    }
);

export const fetchProctorInfo = createAsyncThunk(
    'contest/fetchProctorInfo',
    async (contestId: string) => {
        const data = await services.contestService.fetchProctingInformation({
            contestId: contestId,
        });
        console.log('fetchProtorInfo', data);
        return data;
    }
);

interface ContestState {
    contestId: string | null;
    allContest: any[] | null;
    codingQuestions: any[];
    webDevQuestions: any[];
    conceptualQuestions: any[];
    totalQuestion: number | null;
    solvedQuestion: number | null;
    contestStarted: boolean;
    contestEnded: boolean;
    testAttemptId: string | null;
    hashedTestAttemptId: string | null;
    contestData: any;
    questionData: any;
    isAttempted: boolean;
    isContestLive: boolean;
    timeElapsed: number;
    contestName: string | null;
    totalAttempts: number;
    contestHash: string;
    contestSelected: boolean;
    eligibility: boolean;
    questionsStatusMap: any;
    instructions: string;
    showModal: boolean;
    submitCallMade: boolean;
    forcefullyStart: boolean;
    onGoingContest: any;
    alreadySubmitted: boolean;
    afterSubmissionScore: number;
    // extra
    loading: boolean;
    error: {
        state: boolean;
        message: string;
    };
}

const initialState: ContestState = {
    contestId: null,
    allContest: [],
    contestData: null,
    totalQuestion: 0,
    solvedQuestion: 0,
    codingQuestions: [],
    webDevQuestions: [],
    conceptualQuestions: [],
    contestEnded: false,
    contestStarted: false,
    testAttemptId: null,
    hashedTestAttemptId: null,
    questionData: null,
    isAttempted: false,
    isContestLive: false,
    timeElapsed: 0,
    contestName: null,
    totalAttempts: 0,
    contestHash: CryptoJS.lib.WordArray.random(6).toString(),
    contestSelected: false,
    eligibility: true,
    questionsStatusMap: {},
    instructions: '',
    showModal: false,
    submitCallMade: false,
    forcefullyStart: false,
    onGoingContest: null,
    alreadySubmitted: false,
    afterSubmissionScore: 0,
    // extra
    loading: false,
    error: {
        state: false,
        message: '',
    },
};

export const contestSlice = createSlice({
    name: 'contest',
    initialState,
    reducers: {
        startContest(state) {
            state.contestStarted = true;
            state.contestEnded = false;
        },
        endContest(state) {
            state.contestEnded = true;
            state.contestStarted = false;
        },
        setContestId(state, action) {
            console.log('action.payload', action.payload);
            state.contestId = action.payload;
            console.log('state.contestId', state.contestId);
        },
        setContestSelected(state, action) {
            state.contestSelected = action.payload;
        },
        resetContestData(state) {
            state.contestData = null;
        },
        showEndModal(state) {
            state.showModal = true;
        },
        updateQuestionsStatusMap(state, action) {
            console.log(
                'UPDATE QUESTION',
                state.questionsStatusMap,
                action.payload
            );
            state.questionsStatusMap[action.payload.id] = action.payload;
            console.log(
                'UPDATED QUESTION',
                state.questionsStatusMap,
                action.payload
            );
        },
        forceStart(state, action) {
            state.forcefullyStart = action.payload;
            state.contestEnded = false;
            console.log('force start', action.payload);
        },
        // forceSubmit(state) {
        //     state.forcefullyStart = true;
        //     state.contestEnded = false;
        // },
    },
    extraReducers: {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        [fetchAllContests.pending]: (
            state: ContestState,
            action: PayloadAction<any>
        ) => {
            state.loading = false;
            state.error.state = false;
            state.error.message = '';
        },
        //@ts-ignore
        [fetchAllContests.fulfilled]: (
            state: ContestState,
            action: PayloadAction<any>
        ) => {
            state.allContest = [
                ...action.payload.ongoing,
                ...action.payload.live,
                ...action.payload.upcoming,
            ];
            const isEmpty = action.payload.ongoing.length === 0;
            if (!isEmpty) {
                state.onGoingContest = action.payload.ongoing;
            }
        },

        //@ts-ignore
        [fetchAllContests.rejected]: (
            state: ContestState,
            action: PayloadAction<any>
        ) => {
            state.loading = false;
            state.error.state = true;
            state.error.message = 'Some Error Occurred';
        },
        // @ts-ignore
        [fetchContests.pending]: (
            state: ContestState,
            action: PayloadAction<any>
        ) => {
            console.log('FETCH CONTEST API PENDING', action.payload);
            state.loading = true;
        },
        //@ts-ignore
        [fetchContests.fulfilled]: (
            state: ContestState,
            action: PayloadAction<any>
        ) => {
            console.log('FETCH CONTEST API', action.payload);
            console.log(state.contestData, 'helllo');
            state.loading = false;
            state.contestData = action.payload.contest;
            state.eligibility = action.payload.eligibility;
            state.contestName = action.payload.contest?.name;
            state.contestId = action.payload.contest?.contestId;
            state.isAttempted = action.payload.attemptStatus?.isAttempted;
            state.isContestLive = action.payload.attemptStatus?.isContestLive;
            state.error.state = false;
            state.error.message = '';
            state.instructions = action.payload.contest?.instructions;
        },
        //@ts-ignore
        [fetchContests.rejected]: (
            state: ContestState,
            action: PayloadAction<any>
        ) => {
            console.log('FETCH CONTEST API REJECTED', action);
            state.loading = false;
            state.error.state = true;
            state.error.message = 'Some Error Occurred';
        },
        //@ts-ignore
        [fetchProctorInfo.pending]: (
            state: ContestState,
            action: PayloadAction<any>
        ) => {
            state.loading = true;
        },
        //@ts-ignore
        [fetchProctorInfo.fulfilled]: (
            state: ContestState,
            action: PayloadAction<any>
        ) => {
            console.log('FETCH Proctor API Fulfilled', action);
            state.totalAttempts = action.payload.attemptCount;
            state.isAttempted = action.payload.isAttempted;
            state.isContestLive = action.payload.isContestLive;
            state.testAttemptId = action.payload.testAttemptId;
            state.hashedTestAttemptId = action.payload.hashedTestAttemptId;
            if (
                action.payload.testAttemptId !== null &&
                action.payload.hashedTestAttemptId !== null
            ) {
                localStorage.setItem(
                    'testAttemptId',
                    action.payload.testAttemptId
                );
                localStorage.setItem(
                    'hashedTestAttemptId',
                    action.payload.hashedTestAttemptId
                );
            }
            // let event = new Event('initialize');
            // window.dispatchEvent(event);
        },
        //@ts-ignore
        [fetchProctorInfo.rejected]: (
            state: ContestState,
            action: PayloadAction<any>
        ) => {
            console.log('FETCH Proctor API REJECTED', action);
            state.loading = false;
            state.error.state = true;
            state.error.message = 'Some Error Occurred';
        },
        //@ts-ignore
        [fetchQuestions.pending]: (
            state: ContestState,
            action: PayloadAction<any>
        ) => {
            state.loading = true;
        },
        //@ts-ignore
        [fetchQuestions.fulfilled]: (
            state: ContestState,
            action: PayloadAction<any>
        ) => {
            state.loading = false;
            state.questionData = action.payload;
            state.codingQuestions = action.payload.codingQuestions;
            state.conceptualQuestions = action.payload.conceptualQuestions;
            state.webDevQuestions = [];
            state.totalQuestion =
                action.payload.codingQuestions.length +
                action.payload.conceptualQuestions.length;
            state.isAttempted = action.payload.isAttempted;
            state.isContestLive = action.payload.isContestLive;
            state.timeElapsed = action.payload.timeElapsed;
            state.error.state = false;
            state.error.message = '';

            let solved = 0;

            action.payload.codingQuestions.forEach((el: any) => {
                if (el.isSolved) solved++;
            });

            action.payload.webDevQuestions.forEach((el: any) => {
                if (el.isSolved) solved++;
            });
            state.solvedQuestion = solved;

            for (let i = 0; i < action.payload.codingQuestions.length; i++) {
                const temp = action.payload.codingQuestions[i];
                if (state.questionsStatusMap[temp.questionId] === undefined) {
                    state.questionsStatusMap[temp.questionId] = {
                        id: temp.questionId,
                        score: temp.isSolved ? temp.maxMarks : '0',
                        solved: temp.isSolved,
                    };
                }
            }
        },
        //@ts-ignore
        [fetchQuestions.rejected]: (
            state: ContestState,
            action: PayloadAction<any>
        ) => {
            state.loading = false;
            state.error.state = true;
            state.error.message = 'Some Error Occurred';
        },
        //@ts-ignore
        [submitContest.pending]: (
            state: ContestState,
            action: PayloadAction<any>
        ) => {
            state.submitCallMade = true;
            state.loading = false;
            state.error.state = false;
            state.error.message = '';
        },
        //@ts-ignore
        [submitContest.fulfilled]: (
            state: ContestState,
            action: PayloadAction<any>
        ) => {
            state.contestEnded = true;
            state.isAttempted = true;
            state.afterSubmissionScore = action.payload.totalScore;
            localStorage.removeItem('auto-proctoring');
        },
        //@ts-ignore
        [submitContest.rejected]: (
            state: ContestState,
            action: PayloadAction<any>
        ) => {
            state.loading = false;
            state.error.state = true;
            state.error.message = 'Some Error Occurred';
        },
    },
});

//Exporting Actions
export const {
    startContest,
    endContest,
    setContestId,
    setContestSelected,
    resetContestData,
    updateQuestionsStatusMap,
    showEndModal,
    forceStart,
} = contestSlice.actions;
export default contestSlice.reducer;
