import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { services } from '../../api';

export const userLoginTemp = createAsyncThunk(
    'users/userLoginTemp',
    async (token: any) => {
        try {
            return await services.userService.loginTemp(token);
        } catch (error) {
            console.log(error);
        }
    },
);

export const userLogin = createAsyncThunk(
    'users/userLogin',
    async (token: any) => {
        try {
            return await services.userService.login(token);
        } catch (error) {
            console.log(error);
        }
    },
);

enum loginType {
    login,
    signUp,
}

export interface AppConfigSchema {
    authState: loginType;
    authError: boolean;
    authLoading: boolean;
    authToken: string;
    aptitudeTestStatus: boolean;
}

const initialState: AppConfigSchema = {
    authState: loginType.login,
    authError: false,
    authLoading: false,
    authToken:
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6Imluc3RydWN0b3JzYmF0Y2g4QG1haWxpbmF0b3IuY29tIiwiaWQiOiI2NmEzODU4Ni1lNjYyLTRiOTMtOWYxOC1iYmUyMTc2ZDM4YjQiLCJyb2xlcyI6WyJwcmVjb3Vyc2UtdXNlciJdLCJpYXQiOjE2NTc2MjMxOTB9.UokiEj0QPnDtHCbYHGAd2BKYs4Frqj0-3ao8rVDMQ40',
    aptitudeTestStatus: false,
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setUserFullCourseEnrollmentData: (state) => {
            console.log(state);
        },
        updateAptitudeTestStatus: (state, action: PayloadAction<boolean>) => {
            state.aptitudeTestStatus = action.payload;
        },
    },
    extraReducers: {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        [userLogin.pending]: (
            state: AppConfigSchema,
            action: PayloadAction<any>,
        ) => {
            state.authLoading = true;
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        [userLogin.fulfilled]: (
            state: AppConfigSchema,
            action: PayloadAction<any>,
        ) => {
            state.authLoading = false;
            // state.userReportCard = action.payload.reportCard;
            // state.user = action.payload.user;
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        [userLogin.rejected]: (
            state: AppConfigSchema,
            action: PayloadAction<any>,
        ) => {
            state.authLoading = false;
            state.authError = action.payload;
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        [userLoginTemp.pending]: (
            state: AppConfigSchema,
            action: PayloadAction<any>,
        ) => {
            state.authLoading = true;
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        [userLoginTemp.fulfilled]: (
            state: AppConfigSchema,
            action: PayloadAction<any>,
        ) => {
            state.authLoading = false;
            // state.userReportCard = action.payload.reportCard;
            // state.user = action.payload.user;
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        [userLoginTemp.rejected]: (
            state: AppConfigSchema,
            action: PayloadAction<any>,
        ) => {
            state.authLoading = false;
            state.authError = action.payload;
        },
    },
});

//Exporting Actions
export const {
    // setUserData,
    // setUserReportCardData,
    setUserFullCourseEnrollmentData,
    updateAptitudeTestStatus,
} = authSlice.actions;

export default authSlice.reducer;
