import React, { useState } from 'react';
import Timer from '../../components/timer';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeHighlight from 'rehype-highlight';
import rehypeRaw from 'rehype-raw';
import remarkHtml from 'remark-html';

import '../IDEScreen/QuestionPanel.css';

const Buffer = () => {
    const navigate = useNavigate();

    const [contestTab, setContestTab] = useState([1, 0]);

    const ContestState = useSelector((state: any) => state.contest);

    const { contestName, contestId, instructions } = ContestState;

    const timeleft = new Date();
    timeleft.setSeconds(timeleft.getSeconds() + 10);
    const obj = { expiryTimestamp: timeleft };

    const switchTabs = (tab: number) => {
        // let temp = tab;
        const temp = contestTab.map((el: any, ind: number) => {
            if (ind === tab) return 1;
            else return 0;
        });
        setContestTab(temp);
    };

    const [expired, setExpired] = useState(false);

    const expiryHandler = () => {
        setExpired(true);
    };

    return (
        <div className={'buffer'}>
            <div className={'instructions'}>
                <h1 className={'contest-name'}>{contestName}</h1>
                <h3>Instructions</h3>
                <div className={'instructions-markdown'}>
                    <ReactMarkdown
                        className={`markdown-body`}
                        rehypePlugins={[[rehypeHighlight], [rehypeRaw]]}
                        remarkPlugins={[remarkGfm, remarkHtml]}
                    >
                        {instructions}
                    </ReactMarkdown>
                </div>
                {/*<ul>*/}
                {/*    <li*/}
                {/*        className={`${contestTab[0] === 1 ? 'active' : ''}`}*/}
                {/*        onClick={() => {*/}
                {/*            switchTabs(0);*/}
                {/*        }}*/}
                {/*    >*/}
                {/*        Contest*/}
                {/*    </li>*/}
                {/*    <li*/}
                {/*        className={`${contestTab[1] === 1 ? 'active' : ''}`}*/}
                {/*        onClick={() => {*/}
                {/*            switchTabs(1);*/}
                {/*        }}*/}
                {/*    >*/}
                {/*        Proctor*/}
                {/*    </li>*/}
                {/*</ul>*/}
                {/*{contestTab[0] == 1 ? (*/}
                {/*    <div className={'contest-instructions'}>*/}
                {/*        <h1>Contest Name</h1>*/}
                {/*    </div>*/}
                {/*) : null}*/}
                {/*{contestTab[1] == 1 ? (*/}
                {/*    <div className={'proctor-instructions'}>*/}
                {/*        <h1>Proctor Instruction</h1>*/}
                {/*    </div>*/}
                {/*) : null}*/}
            </div>
            <div className={'wait'}>
                <h1>Please Read the instructions carefully</h1>
                <Timer
                    show={[0, 0, 1, 1]}
                    expiryTimestamp={timeleft}
                    expiryHandler={expiryHandler}
                />
                <ol className={'wait-list'}>
                    <li>You can start your test after this timer expiry.</li>
                    <li>
                        You can slot time will start as you begin your test.
                    </li>
                </ol>
                <button
                    className={'start-test-btn'}
                    disabled={!expired}
                    onClick={() => {
                        window.open(
                            `${window.origin}/contest?contestID=${contestId}`,
                            '_blank'
                        );
                        // navigate('/contest');
                    }}
                >
                    Start Test
                </button>
            </div>
        </div>
    );
};

export default Buffer;
