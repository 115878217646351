import { apiFailureErrorMessage, HttpService } from '../utils/httpService';
import { ENDPOINTS } from '../config/endpoints/stage';

export class UserService {
	constructor(private httpService: HttpService) {}

	async login(loginDetails: any) {
		const url = `${ENDPOINTS.ROOT}/users/login`;
		try {
			const { data } = await this.httpService.post<any>(url, {
				data: loginDetails,
			});
			console.log('Login', data);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async loginTemp(loginDetails: any) {
		const url = `${ENDPOINTS.ROOT}/users/admin/login/only`;
		try {
			const { data } = await this.httpService.post<any>(url, {
				data: loginDetails,
			});
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async signup(signupDetails: any) {
		const url = `${ENDPOINTS.ROOT}/users/signup`;
		try {
			const { data } = await this.httpService.post<any>(url, {
				data: signupDetails,
			});
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getAptTestResult() {
		const url = `${ENDPOINTS.ROOT}/user-report-card/apt-test-status`;
		try {
			const { data } = await this.httpService.get<any>(url);
			console.log('apt-test-status', data);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getReport() {
		const url = `${ENDPOINTS.ROOT}/user-report-card`;
		try {
			console.log('dome', url);
			const { data } = await this.httpService.get<any>(url);
			console.log('report', data);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async enrollPreCourse() {
		const url = `${ENDPOINTS.ROOT}/course/enrollPreCourse`;
		try {
			const { data } = await this.httpService.post<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async addedEvent(id: string) {
		const url = `${ENDPOINTS.ROOT}/users/added_event/` + id;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getMonthyChartData() {
		const url = `${ENDPOINTS.ROOT}/user-score-card/monthlyQuestions`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async markChoseRecorded(id: string) {
		const url = `${ENDPOINTS.ROOT}/users/get_recorded/` + id;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async sendOTP(phoneNumber: string) {
		const url = `${ENDPOINTS.ROOT}/users/sendOTP`;
		try {
			const { data } = await this.httpService.post<any>(url, {
				data: {
					phoneNumber,
				},
			});
			return data;
		} catch (error) {
			debugger;
			throw new Error(apiFailureErrorMessage);
		}
	}

	async verifyOTP(phoneNumber: string, otp: string, countrycode: number) {
		const url = `${ENDPOINTS.ROOT}/users/verifyOTP`;
		try {
			const { data } = await this.httpService.post<any>(url, {
				data: {
					phoneNumber,
					otp,
					countrycode,
				},
			});
			return data;
		} catch (error) {
			debugger;
			throw new Error(apiFailureErrorMessage);
		}
	}

	async submitUgandaOTP(phoneNumber: string, countrycode: number) {
		const url = `${ENDPOINTS.ROOT}/users/verifyUgandaOTP`;
		try {
			const { data } = await this.httpService.post<any>(url, {
				data: {
					phoneNumber,
					countrycode,
				},
			});

			return data;
		} catch (error) {
			debugger;
			throw new Error(apiFailureErrorMessage);
		}
	}

	async updateCollegeDetails(graduationYear: number) {
		const url = `${ENDPOINTS.ROOT}/users/updateCollegeDetails`;
		try {
			const { data } = await this.httpService.post<any>(url, {
				data: {
					graduationYear,
				},
			});
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async updateUserDetails(
		firstName: any,
		lastName: any,
		graduationYear: any
	) {
		const url = `${ENDPOINTS.ROOT}/users/updateUser`;
		try {
			const { data } = await this.httpService.patch<any>(url, {
				data: {
					firstName,
					lastName,
					graduationYear,
				},
			});
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getMockTestLink(id: any) {
		const url = `${ENDPOINTS.ROOT}/user-report-card/mock-job-test-url/${id}`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getNewAptTestLink() {
		const url = `${ENDPOINTS.ROOT}/user-report-card/get-admission-apt-test-link-after-failure`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getReferralsCount() {
		const url = `${ENDPOINTS.ROOT}/users/referrals`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getProfile() {
		const url = `${ENDPOINTS.ROOT}/user-profile`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async postProfile(resume_link: string, resume_file_name: string) {
		const url = `${ENDPOINTS.ROOT}/user-profile/update`;
		try {
			const { data } = await this.httpService.post<any>(url, {
				data: {
					resume_link,
					resume_file_name,
				},
			});
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async bookInterview(
		college: FormDataEntryValue,
		degree: FormDataEntryValue,
		whatsappNumber: FormDataEntryValue,
		class_10_percentage: FormDataEntryValue,
		class_12_percentage: FormDataEntryValue,
		cgpa: FormDataEntryValue,
		resume: string,
		department: FormDataEntryValue,
		parentsContactNumber: FormDataEntryValue,
		collegeState: FormDataEntryValue,
		homestate: FormDataEntryValue
	) {
		const url = `${ENDPOINTS.ROOT}/user-profile/book-interview`;
		try {
			const { data } = await this.httpService.post<any>(url, {
				data: {
					college,
					degree,
					whatsappNumber,
					class_10_percentage,
					class_12_percentage,
					cgpa,
					resume,
					department,
					parentsContactNumber,
					collegeState,
					homestate,
					// interview_slot,
				},
			});
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async callbackRequest(
		callbackRequest: boolean,
		callbackRequestTime: string
	) {
		const url = `${ENDPOINTS.ROOT}/users/callbackRequest`;
		try {
			const { data } = await this.httpService.post<any>(url, {
				data: { callbackRequest, callbackRequestTime },
			});
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async joinedLiveClass() {
		const url = `${ENDPOINTS.ROOT}/users/joinedLiveClass`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getAvailableSlots(date: string, sessionID: string) {
		const url = `${ENDPOINTS.ROOT}/session/getAvailaibleSlots`;
		try {
			const { data } = await this.httpService.post<any>(url, {
				data: {
					date: date,
					sessionId: sessionID,
				},
			});
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async bookSessionWithMentor(date: string, sessionID: string, slot: string) {
		const url = `${ENDPOINTS.ROOT}/session/bookSessionWithMentor`;
		try {
			const { data } = await this.httpService.post<any>(url, {
				data: {
					date: date,
					sessionId: sessionID,
					slot: slot,
				},
			});
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}
}
