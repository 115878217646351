import { useEffect, useState } from 'react';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import Cookies from 'js-cookie';

// Only External Exports Above
import { useReduxDispatch } from '../redux/store';
import { setUserName } from '../redux/user/userSlice';

type tokenInterface = JwtPayload & {
    email: string;
    roles: any[];
};

const useLoginHook = () => {
    const dispatch = useReduxDispatch();

    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const extractUserData = (token: string) => {
        const tokenData = jwt_decode<tokenInterface>(token);
        const role = tokenData.roles[0];
        console.log('username', tokenData);
        dispatch(setUserName('Learner'));
        // if (role === 'precourse-user') {
        //     window.open('https://precourse.acciojob.com', '_self');
        // } else
        {
            setIsAuthenticated(true);
        }
    };

    const getCredentials = async () => {
        if (process.env.REACT_APP_TOKEN) {
            const localToken = process.env.REACT_APP_TOKEN;
            await localStorage.setItem('acciojobs-token', localToken);
            extractUserData(localToken);
        } else {
            const domain = '.acciojob.com';
            const userTokenFromCookie = Cookies.get('acciojobs-token');
            if (userTokenFromCookie) {
                await localStorage.setItem(
                    'acciojobs-token',
                    userTokenFromCookie
                );
                extractUserData(userTokenFromCookie);
            } else window.open('https://auth.acciojob.com/login', '_self');
        }
    };

    useEffect(() => {
        getCredentials();
    }, []);

    return isAuthenticated;
};

export default useLoginHook;
