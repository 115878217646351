import React from 'react';
import { Route, Routes } from 'react-router-dom';
import IDEScreen from '../IDEScreen/IDEScreen';
import WebDevScreen from '../webdevidle/WebDevScreen';
import QuestionNavigator from '../../components/questionNavigator';
import Aptitude from '../aptitute/aptitude';

const QuestionScreen = () => {
    return (
        <div className={'question-screen'}>
            <QuestionNavigator />
            <Routes>
                <Route
                    path={'conceptual-question'}
                    element={<Aptitude />}
                />
                <Route
                    path={'coding-question'}
                    element={<IDEScreen />}
                />
                <Route
                    path={'web-dev-question'}
                    element={<WebDevScreen />}
                />
            </Routes>
        </div>
    );
};

export default QuestionScreen;
