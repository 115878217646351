export const ENDPOINTS = {
    // ROOT: "https://accio-release-1-dot-acciojob-prod.el.r.appspot.com",
    ROOT: 'https://accio-release-data-dot-acciojob-prod.el.r.appspot.com',
    // ROOT: 'https://acciojob-app-backend-2-pro-fohi8.ondigitalocean.app',
    // ROOT: 'https://67d6-45-251-49-53.ngrok.io'
    // ROOT: 'https://acciojob-app-backend-2-pro-fohi8.ondigitalocean.app',
    // ROOT: 'https://5500-beige-meadowlark-x3367ly3.ws-us25.gitpod.io',
};

// ROOT: 'https://intense-river-03432.herokuapp.com'
